body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #00ffcd;
  justify-content: center;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

@font-face {
  font-family: "KenneyFutureSquare";
  src: local("KenneyFutureSquare"),
    url("./fonts/KenneyFutureSquare.ttf") format("truetype");
}

@font-face {
  font-family: "DMSans-Regular";
  src: local("DMSans-Regular"),
    url("./fonts/DMSans-Regular.ttf") format("truetype");
}
