.App {
  text-align: center;
}

.background-colour {
  background-color: #2cb2cb;
}

.container {
  width: 70%;
  padding: 3rem;
  margin-left: 10px;
}

.text-container {
  width: 70%;
  padding: 3rem;
}

.justify {
  text-align: left;
}

.Nav {
  height: 10px;
  display: flex;
  align-items: center;
  /* other styling as needed */
}

/* Style for NavMenu for larger screens */
.NavMenu {
  display: flex;
  margin-top: 3.5rem;
  margin-bottom: 1rem;
  margin-left: 1rem;;
  /* other styling as needed */
}

/* Responsive style for NavMenu for smaller screens */
@media only screen and (max-width: 800px) {
  .NavMenu {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .Nav {
    height: auto; /* Allow the navbar to expand as needed */
  }

  .NavLink, a {
    width: 100%;
    text-align: center;
  }

}

@media only screen and (max-width: 800px) {
  .container,
  .text-container {
    width: 90%; /* Set to a higher percentage for smaller screens */
    padding: 2rem; /* Adjust padding for mobile devices */
    margin: 0; /* Reset margin */
  }

  .text-canvas {
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
  }
}

@media only screen and (min-width: 800px) {
  .container {
    margin-left: 80px;
  }
}

@media only screen and (min-width: 1000px) {
  .container {
    margin-left: 120px;
  }
}

@media only screen and (min-width: 2000px) {
  .container {
    margin-left: 275px;
  }
}

.App-logo {
  padding-top: 10px;
  height: 65vmin;
  pointer-events: none;
}

.App-gameplay {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: pulse 1s linear infinite;
  }
}

.font-low {
  font-family: "KenneyFutureSquare";
}

.font-DMSans {
  font-family: "DMSans-Regular";
}

.black-text {
  color: black;
  padding: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.App-header {
  background-color: #2cb2cb;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.text-canvas {
  background-color: black;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.text-title {
  color: #00ffcd; /* Ensure this is a valid color code */
}

@keyframes pulse {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.03, 1.03);
  }

  100% {
    transform: scale(1, 1);
  }
}
